.main_wrapper {
  padding-top: 15px;

  // padding-top: 15px;
  // position: fixed;
  // background: white;
  // z-index: 999999;
  // width: 100%;
  // box-shadow: 1px 1px 20px #00000017;
  // left: 0;

  .navbar_fix {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin: auto;
    li {
      margin-left: 50px;
      a {
        cursor: pointer;
        font-family: 'Inter-Variable';
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2f4858;
      }
    }
  }

  .inner_pad {
    padding: 0 50px;
  }
  #navbarDropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  
  .custom_btn {
    padding: 0px 25px;
    border-radius: 4px;
    border: solid 1px #f14d03;
    &:hover {
      background-color: #f14d03;
      a {
        color: white;
      }
    }
    a:hover {
      color: white !important;
    }
    
  }
  .round_language {
    padding: 0px 10px;
    border-radius: 4px;
    border: solid 1px #bdbdbd;
    background-color: #ffffff;
  }
  .dropdownmenu_custom {
    min-width: auto !important;
  }
  .custom_arrow::after {
    display: inline-block;
    margin-left: 0.655em;
    margin-top: 0.15em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
}

.fix_responsive_logo {
  display: none;
}

@media (max-width: 768px) { 
  .fix_responsive_logo {
    display: block;
  }
  .inner_pad {
    padding: 0 !important;
  }
  .fix_responsive_logo_hide {
    display: none !important;
  }
  .main_wrapper {
    padding-top: 15px;
    position: fixed;
    background-color: white;
    height: 80px;
    z-index: 8888888888888;
    width: 100%;
    padding: 10px 12px;
    left: 0;
    box-shadow: 1px 1px 19px #bcbcbc;
    .custom_btn {
      padding: 0px 25px;
      border-radius: 4px;
      border: solid 1px #f14d03;
      padding: 10px !important;
    }

    .custom_arrow {
      padding: 10px;
    }
    .navbar_fix  {
      display: flex;
      align-items: flex-start;
      li {
        margin-left: 25px;
        padding: 15px 0;
        // border-bottom: solid 1px #7d7d7d52;
        width: 83%;
        margin-bottom: 7px;
      }
    }
  }


  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    padding: 10px;
    border-bottom: solid 1px #9595954a;
  }

}
