.main_wrapper_p {
    display: block;
    background-image: url("./../../assets/img/main_back.svg");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    .res_fix {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-bottom: 150px;
    }

    .formfix {
        display: flex;
        justify-content: flex-end;
        background-image: url("./../../assets/img/modern_life.svg");
        background-repeat: no-repeat;
        background-size: 480px;
        background-position: left center;
        height: 403px;
    }

    .regions_img {
        display: flex;
        justify-content: flex-end;
        background-image: url("./../../assets/img/suburbs.svg");
        background-repeat: no-repeat;
        background-size: 480px;
        background-position: center -100%;
        height: 370px;
    }

    .text_content {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        margin-left: 20px;

        h1 {
            font-family: "NunitoSans";
            font-size: 40px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.07;
            letter-spacing: normal;
            color: #f14d03;
            margin-bottom: 25px;
        }
        p {
            font-family: "Inter-Variable";
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #2f4858;
            margin-bottom: 38px;
        }
        .row {
            width: 100%;
            div {
                button {
                    background-color: #2f4858;
                    border-color: #2f4858;
                    font-family: NunitoSans;
                    font-size: 19px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #ffffff;
                }
            }
        }

        .regions_points {
            p {
                margin-bottom: 10px;
            }

            .buttonenv {
                padding: 11px 103.5px 10px;
                border-radius: 8px;
                border-color: #f14d03;
                background-color: #f14d03;
                width: auto;
            }

            @media (max-width: 728px) {
                .buttonenv {
                    padding: 11px 50px 10px;
                    width: 100%;
                }
            }
        }

        @media (max-width: 960px) {
            .regions_points {
                width: 100%;
            }
        }

        @media (max-width: 640px) {
            .row {
                margin-right: 0;
                margin-left: 0;
            }

            .btn_partners_regions {
                padding-left: 0;
            }

            .btn_partners_company {
                padding-right: 0;
            }
        }

        @media (max-width: 530px) {
            .btn_partners {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    .regions_container {
        padding-bottom: 100px;
    }

    @media (max-width: 968px) {
        .formfix,
        .regions_img {
            display: none;
        }

        .text_container {
            width: 100%;
            max-width: 100%;
            flex: unset;
        }

        .res_fix {
            margin-bottom: 50px;
        }

        .text_content {
            margin: 0 10px;
        }
    }
}
@media only screen and (min-width: 1112px) and (max-width: 1115px) {
    .res_fix {
        background-size: 640px !important;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1025px) {
    .res_fix {
        background-size: 740px !important;
    }
}

@media (max-width: 768px) {
    .main_wrapper_p {
        margin-bottom: 0px;
        padding-top: 122px;
    }
    .res_fix {
        display: flex;
        flex-direction: column-reverse !important;
    }
}
